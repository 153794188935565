























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
.OpStudentstatistics {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .completeStates {
    .el-form-item__label {
      min-width: 7rem;
    }
    .el-form-item__content {
      margin-left: 7rem !important;
    }
  }
}

.custom {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5c6be8;
    border-color: #5c6be8;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5c6be8;
  }
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
